interface BackgroundImageProps {
  src?: string;
  color?: string;
  className?: string;
  zIndex?: number | "auto";
}

function BackgroundImage({
  src,
  color,
  className = "",
  zIndex = -1,
}: BackgroundImageProps): JSX.Element {
  return (
    <>
      <div
        className={`w-100 h-100 i-town-background-image ${className}`}
        style={
          src
            ? { backgroundImage: `url("${src}")`, zIndex: zIndex }
            : color
            ? { backgroundColor: color, zIndex: zIndex }
            : {}
        }
      ></div>
    </>
  );
}

export default BackgroundImage;
