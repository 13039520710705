import { Col, Row } from "react-bootstrap";
import { Heading3, Paragraph3 } from "../atoms/Typography";
import Layout from "../template/Layout";
const items = [
  <>
    レコーディング ¥5,000- / 時間 ¥30,000 / 日
    <br />
    ミキシング / マスタリング(シングル) ¥20,000
    ...各ディレクション/エンジニア料金を含む
  </>,
  <>
    ※エンジニア/イベンターなど企業様向けに箱貸しも行っております。詳しくはお問い合わせください
  </>,
  <>
    トラックメイク ¥50,000
    <br />
    作曲(メロディライン) / 作詞 ¥30,000
  </>,
  <>
    その他、楽曲提供に限らず宣材の作成(写真、動画、デザインなど)アーティストプロモーション全般も承ります。
  </>,
  <>
    ※芸能プロダクションなど、事務所や企業様向けに提携も行っております。
    詳しくはお問い合わせください
  </>,
  <>
    <a href="mailto:office_itown_co&#64;icloud.com">お問い合わせ先 ▼ </a>
  </>,
];
function Plan(): JSX.Element {
  return (
    <Layout.Empty
      footnote={
        <Paragraph3 color="black">
          ※料金は参考価格です。担当者や時期などにおいて変動することがあり、またご予算に合わせてのご相談も承ります。
        </Paragraph3>
      }
    >
      <Heading3 color="black" className="mb-3">
        楽曲制作など/プラン一覧
      </Heading3>
      <Row className="w-100">
        <Col xs={0} md={1}></Col>
        <Col>
          <ul className="m-0 p-0 w-100 px-3 px-md-0">
            {items.map((item, i) => {
              return (
                <li key={i} className="mb-2">
                  <div className="alert plan-info mb-0 shadow-sm">{item}</div>
                </li>
              );
            })}
          </ul>
        </Col>
        <Col xs={0} md={1}></Col>
      </Row>
      <div
        className="w-100 h-100 position-absolute bg-content"
        style={{ top: 0, left: 0, zIndex: -1 }}
      ></div>
    </Layout.Empty>
  );
}
export default Plan;
