import Plus from "../atoms/Plus";
import { Heading2, Paragraph3 } from "../atoms/Typography";
import { List } from "../organisms/List";
import Layout from "../template/Layout";
const items = [
  "アーティストとしても長く活動してきた私自身 (弊社代表 K'S-J)",
  "冒頭に触れたような悩みを長年抱えてきました。近年、活動のご縁から楽曲提供やエンジニア、プロデューサーとしてのお仕事を通して、様々なアーティスト様の音楽に触れる中で、皆さん一様にそうした想いを抱えられていることに気づき、私が携わる音楽くらいはそういったイメージを払拭した、純度の高いものにしたいとずっと考えてきました。",
  "そうした中で、このスタジオを継がせて頂くこととなり、この環境に触れ世界が変わることになります。",
  `"ここならそれができる!!" 確信した瞬間でした。`,
  "再三記載してきましたが音楽家にとってのベストな環境とは、荘厳なだけのハイスペックなスタジオでもダラダラと好きなだけ制作 ができるご自宅やあるいはそれに近いスタジオでもなくその両面の良さを兼ね備えたスタジオなのです。",
  "ここに多くのアーティストや、音楽家の皆様が訪れディスカッションし、生まれた音楽が世に発信されていくそんなに嬉しいことが、他にあるでしょうか？",
  "願わくば、このスタジオがあなたの夢を叶える一つの要因となり数年後、数十年後もここでお酒でも酌み交わしながら",
  "今日という日のことを語らえたのなら、音楽家冥利に尽きるというものです。",
  "「そんなこともあったな！笑...でさ？今回の新曲についてなんやけど!!」そんな未来にて、またお会いしましょう。",
];

function Closing3(): JSX.Element {
  const ClosingList: JSX.Element[] = ((): JSX.Element[] => {
    return items.map((item, i) => {
      return (
        <List.Group key={i}>
          <List.Item
            body={
              <Paragraph3>
                <>
                  <Plus />
                  {item}
                </>
              </Paragraph3>
            }
            listStyle="plus"
          />
        </List.Group>
      );
    });
  })();
  return (
    <Layout.Horizontal
      heading={
        <Heading2 position="start">このスタジオには"想い"があります</Heading2>
      }
      body={ClosingList}
      bgSrc="/images/home-8.jpg"
    />
  );
}
export default Closing3;
