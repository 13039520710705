import { Heading2, Paragraph2 } from "../atoms/Typography";
import { List } from "../organisms/List";
import Layout from "../template/Layout";

function Head2(): JSX.Element {
  return (
    <Layout.Horizontal
      heading={
        <Heading2 position="start">
          刺激×リラックスの調和こそがクリエイティブを実現させる。
        </Heading2>
      }
      body={
        <List.Group>
          <List.Item
            heading="こんな経験ありませんか？"
            body={
              <Paragraph2>
                <>
                  ○高いお金を払って、有名なスタジオをレンタルしたのに、緊張で実力を発揮できなかった。
                  <br />
                  ○エンジニアさんが機械的で、音楽的な時間を過ごすことができなかった。{" "}
                  <br />
                  ○宅撮りでたっぷりと時間をかけて、最高のテイクが撮れたのに、どうしても自信のある作品にならない。{" "}
                  <br />
                  →その全てを解消します。
                </>
              </Paragraph2>
            }
            listStyle="plus"
          />
        </List.Group>
      }
      bgSrc="/images/home-3.jpg"
    />
  );
}
export default Head2;
