import { Alert, Col, Row } from "react-bootstrap";
import BackgroundImage from "../atoms/BackgroundImage";
import { Heading2, Heading3, Paragraph2 } from "../atoms/Typography";
import Layout from "../template/Layout";
const items = [
  <>
    スタジオ情報 <br />
    office I-town 運営
    <br />
    レコーディング/音楽制作スタジオ
  </>,
  <>
    I-town studio <br />
    愛知県名古屋市中区大須主要部 <br />
    詳細はご予約時にお伝えしております。
  </>,
  <>当スタジオは完全予約制となります。</>,
  <>
    無料見学、ご相談受付中!!お気軽にお問い合わせください。 <br />
    各種お問い合わせは{" "}
    <a href="mailto:office_itown_co&#64;icloud.com">こちらから</a>
  </>,
];
function Access(): JSX.Element {
  return (
    <Layout.Empty>
      <div className="px-3 px-md-5 w-100">
        <Heading3 color="black" className="mb-3">
          <>
            名城線矢場町駅から徒歩10分!!
            <br />
            名古屋市中心部に位置する音楽スタジオです!
          </>
        </Heading3>
        <Row className="w-100 m-0">
          <Col xs={0} md={1}></Col>
          <Col xs={12} md={10}>
            <ul className="ps-0">
              {items.map((item, i) => {
                return (
                  <li
                    key={i}
                    className="border-top border-2 border-secondary py-1 my-3"
                  >
                    <Paragraph2 color="black">{item}</Paragraph2>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xs={0} md={1}></Col>
        </Row>
      </div>
      <div
        className="w-100 h-100 position-absolute bg-content"
        style={{ top: 0, left: 0, zIndex: -1 }}
      ></div>
    </Layout.Empty>
  );
}
export default Access;
