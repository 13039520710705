import { Heading1, Paragraph2, Paragraph3 } from "../atoms/Typography";
import HrPlus from "../molecules/HrPlus";
import Layout from "../template/Layout";

function Closing2(): JSX.Element {
  return (
    <Layout.Vertical
      heading={
        <Heading1 spacing={4}>
          <>
            エンジニアは最高の相棒
            <br />
            であるべきです。
          </>
        </Heading1>
      }
      body={
        <>
          <HrPlus />
          <Paragraph2 spacing={2} position="center">
            <>
              また当スタジオを担当するエンジニアは、それぞれがアーティストやクリエイターとしての活動実績を持
              ち 音楽を愛するエンジニアばかりです。
              <br />
              ですから、常に"この作品をより良いものにするにはどうすればいいのか?"を
              あなたと同じレベルで考えて、作品作りを行います。
              <br />
              実力をフルに発揮できる空間と、それを引き上げる設備
              <br />
              最後に音楽を愛するエンジニアのノウハウと熱量
              それらの化学変化があなたの音楽を新たなステージに昇華させます。
            </>
          </Paragraph2>
        </>
      }
      bgSrc="/images/home-7.jpg"
    />
  );
}
export default Closing2;
