import { Col, Row } from "react-bootstrap";
import { Heading1, Paragraph1 } from "../atoms/Typography";
import Layout from "../template/Layout";

function Head1(): JSX.Element {
  return (
    <Layout.Empty bgSrc="/images/home-2.jpg">
      <Row className="w-100 h-100 px-md-5 px-3">
        <Col xs={0} md={4}></Col>
        <Col xs={12} md={8} className="d-flex flex-column h-100">
          <Heading1 position="start">創造が爆発する</Heading1>
          <Paragraph1>
            Studio I-town は 最高に刺激的なスタジオ設備に加え
            完全貸切のプライベート空間を提供します。
            カフェのようにリラックスできるその秘密基地は
            あなたの創造力を爆発させます。
          </Paragraph1>
        </Col>
      </Row>
    </Layout.Empty>
  );
}
export default Head1;
