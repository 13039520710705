import { Heading2, Paragraph3 } from "../atoms/Typography";
import { List } from "../organisms/List";
import Layout from "../template/Layout";

const items = [
  {
    heading: "マイク",
    body: "SLATE DIGITAL ML-1 MODELING MICROPHONE",
  },
  {
    heading: "オーディオインターフェース",
    body: "UNIVERSAL AUDIO ( ユニバーサルオーディオ ) / APOLLOX6 Heritage EditionFireface 800",
  },
  {
    heading: " コンソーラー / ミキサー",
    body: `YAMAHA DM1000
   YAMAHA MG10(レコーディングブース)`,
  },
  { heading: "プリアンプ", body: "SLATE DIGITAL VMS-One" },
  {
    heading: "モニターシステム",
    body: ` AIR SERIES(メインスピーカー)
    CENTRAL STATION`,
  },
  {
    heading: "ヘッドフォン",
    body: `audio technica/ ATH-M70x (モニターヘッドフォン)
    SONY / MDR-CD900ST (レコーディングブース)`,
  },
  {
    heading: "その他",
    body: `smart digital (周波数帯 整流器)
    各電源ケーブル oyaide採用
    3KVA TRANSLE(電圧安定供給)
    制作スタジオ全面防音/独立のレコーディングブース完備`,
  },
];

function Gear(): JSX.Element {
  const GearList: JSX.Element[] = ((): JSX.Element[] => {
    return items.map((item, i) => {
      return (
        <List.Group key={i}>
          <List.Item
            heading={item.heading}
            body={<Paragraph3>{item.body}</Paragraph3>}
            listStyle="plus"
          />
        </List.Group>
      );
    });
  })();
  return (
    <Layout.Horizontal
      heading={<Heading2 position="start">導入機材/システム一覧</Heading2>}
      body={GearList}
      bgSrc="/images/home-5.jpg"
      footnote={
        <Paragraph3>
          <>
            ※記載は主なレコーディングルーティンを抜粋しております
            <br />
            ※2一部の機材は担当のエンジニアの裁量で変更する場合がございます
          </>
        </Paragraph3>
      }
    />
  );
}
export default Gear;
