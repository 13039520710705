import Layout from "../template/Layout";
import { Heading1, Paragraph1 } from "../atoms/Typography";
import HrPlus from "../molecules/HrPlus";

function Introduction(): JSX.Element {
  return (
    <Layout.Vertical
      heading={<Heading1 spacing={0}>Studio I-town</Heading1>}
      body={
        <>
          <HrPlus />
          <Paragraph1 position="center">
            <>
              ようこそ
              <br />
              理想を現実にする最高峰のプライベートスタジオへ
            </>
          </Paragraph1>
        </>
      }
      bgSrc="/images/home-1.jpg"
    />
  );
}

export default Introduction;
