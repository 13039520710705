import "./style/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.scss";
import Home from "./pages/Home";

function App() {
  return <Home />;
}

export default App;
