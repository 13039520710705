import Plus from "../atoms/Plus";
import { Heading2, Paragraph3 } from "../atoms/Typography";
import { List } from "../organisms/List";
import Layout from "../template/Layout";
const items = [
  "Studio I-townは",
  "代表作 ココロオドルなどの大ヒットによりNHK紅白歌合戦への出場(2004年)を果たした他、現在は愛知県公認の「あいち音楽大使」を委嘱するnobodyknows+より受け継いだ歴史あるスタジオです。(元:Sigma Sounds Studio)",
  "当時の機材もほとんどそのままに受け継ぎ、一部は最新の音楽シーンにフィットしたこのスタジオに相応しいマシーンを導入。",
  "制作スペースは全面完全防音で、レコーディングブースはかつてあなたが訪れた如何なる空間よりも、静寂でデッド。あなたの魅力を最大限に引き出します。",
  "ここから生まれた作品は数知れず",
  "nobodyknows+は勿論、同郷のスーパースター SEAMO や果てはグラミー賞10冠に輝くかのJohn Legendもクルーで滞在し「ナゴヤならココしかないだろ!」と言わしめた。(DJMITSU 22.03,17 Tweetより",
  "HIPHOP,J-POP,洋楽のチャートインミュージックにまで名を刻むこの場所から、新たな伝説をスタートさせるのはあなたかも知れません。",
];
function StudioITown(): JSX.Element {
  const studioItownList: JSX.Element[] = ((): JSX.Element[] => {
    return items.map((item, i) => {
      return (
        <List.Group key={i}>
          <List.Item
            body={
              <>
                <Paragraph3>
                  <Plus />
                  <>{item}</>
                </Paragraph3>
              </>
            }
            listStyle="plus"
          />
        </List.Group>
      );
    });
  })();
  return (
    <Layout.Horizontal
      heading={
        <Heading2 position="start">
          <>
            SONYが手がけ多くの著名アーティストの作品を生み出してきた。
            <br />
            実績ある音楽スタジオ
          </>
        </Heading2>
      }
      body={studioItownList}
      bgSrc="/images/home-4.jpg"
    />
  );
}
export default StudioITown;
