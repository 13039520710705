import Plus from "../atoms/Plus";
import { Heading2, Paragraph2 } from "../atoms/Typography";
import Layout from "../template/Layout";

function AboutITown(): JSX.Element {
  return (
    <Layout.HorizontalBgImage
      heading={
        <div className="d-flex flex-column flex-nowrap">
          <Heading2 color="black" position="start" spacing={0} className="">
            <>
              office I-town
              <br />
              について
            </>
          </Heading2>
          <ul className="p-0">
            <li>
              <Paragraph2 color="black" className="">
                <>
                  <Plus />
                  I-townは所属するアーティストやクリエイター、そして応援してくれるファンの皆様によって構成される一つの"Crew"という想いのもとに結成され、現在では会社として独立。音楽/芸能事務所として活動の幅を広げています。
                </>
              </Paragraph2>
            </li>
          </ul>
        </div>
      }
      bgSrc={"/images/icon.png"}
      bgSize="contain"
      bgColor="#ffffff"
      className="bg-content"
    />
  );
}
export default AboutITown;
