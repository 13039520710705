import Plus from "../atoms/Plus";
import { Heading2, Paragraph2 } from "../atoms/Typography";
import { List } from "../organisms/List";
import Layout from "../template/Layout";
const items = [
  `音楽設備が申し分ないのは既にお分かりいただけたかと思います。
しかし、音楽家 アーティスト アイドルやディレクター プロデューサーの皆様において、最も必要なのはあなたの実力をフィルターなく発揮できる空間です。`,
  `まるでカフェ あるいはご自宅。リラックスできる空間こそがあなたの創造性を高めくれます。`,
  `本番は練習の80%の...なんてことをよく耳にしますが、レコーディングや音楽制作において、それは実にナンセンスです。
この環境はあなたの才能を、あるいは重ねてきた努力を120%引き出します。`,
];
function Closing1(): JSX.Element {
  const ClosingList: JSX.Element[] = ((): JSX.Element[] => {
    return items.map((item, i) => {
      return (
        <List.Group key={i}>
          <List.Item
            body={
              <Paragraph2>
                <>
                  <Plus />
                  {item}
                </>
              </Paragraph2>
            }
            listStyle="plus"
          />
        </List.Group>
      );
    });
  })();
  return (
    <Layout.Horizontal
      heading={
        <Heading2 position="start">
          クリエイティブを引き出すプライベート空間
        </Heading2>
      }
      body={ClosingList}
      bgSrc="/images/home-6.jpg"
    />
  );
}
export default Closing1;
