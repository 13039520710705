import AboutITown from "./AboutITown";
import Access from "./Access";
import Artist from "./Artist";
import Closing1 from "./Closing1";
import Closing2 from "./Closing2";
import Closing3 from "./Closing3";
import Gear from "./Gear";
import Head1 from "./Head1";
import Head2 from "./Head2";
import Introduction from "./Introduction";
import Performance1 from "./Performance1";
import Performance2 from "./Performance2";
import Plan from "./Plan";
import RepresentativeProfile from "./RepresentativeProfile";
import StudioITown from "./StudioITown";

function Home(): JSX.Element {
  return (
    <>
      <Introduction />
      <Head1 />
      <Head2 />
      <StudioITown />
      <Gear />
      <Closing1 />
      <Closing2 />
      <Closing3 />
      <Access />
      <Plan />
      <AboutITown />
      {/* <Artist />
      <Performance1 />
      <Performance2 />
      <RepresentativeProfile /> */}
    </>
  );
}

export default Home;
