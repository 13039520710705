import Plus from "../atoms/Plus";
import { Paragraph2 } from "../atoms/Typography";

function ItemBase({
  children,
}: {
  children: JSX.Element | JSX.Element | undefined;
}): JSX.Element {
  return <></>;
}

interface listItemProps {
  heading?: string;
  body?: string | JSX.Element | JSX.Element[];
  listStyle?: "none" | "plus";
}
function Item({
  heading,
  body,
  listStyle = "plus",
}: listItemProps): JSX.Element {
  return (
    <li className="text-light list-item">
      <Paragraph2>
        <>
          {listStyle === "plus" && heading ? <Plus /> : <></>}
          {heading}
        </>
      </Paragraph2>
      <>{body}</>
    </li>
  );
}

function Group({
  children,
}: {
  children: JSX.Element | JSX.Element | undefined;
}): JSX.Element {
  return <ul className="mb-0 ps-0 ps-md-5">{children}</ul>;
}

export const List: {
  Group: (children: {
    children: JSX.Element | JSX.Element | undefined;
  }) => JSX.Element;
  Item: ({ heading, body, listStyle }: listItemProps) => JSX.Element;
} = {
  Group,
  Item,
};
