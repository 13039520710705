type color = "white" | "black";
type position = "start" | "center" | "end";
const textColorHandle = (color: color): string =>
  `text-${color === "white" ? "light" : "black-i"}`;
interface Typography {
  children: JSX.Element | JSX.Element[] | string;
  color?: color;
  position?: position;
  spacing?: number;
  className?: string;
}
export const Heading1 = ({
  children,
  color = "white",
  position = "center",
  spacing = 2,
  className = "",
}: Typography): JSX.Element => (
  <h1
    className={`display-1 fw-bolder letter-spacing-${spacing} text-${position} ${textColorHandle(
      color
    )}  ${className}`}
  >
    {children}
  </h1>
);

export const Heading2 = ({
  children,
  color = "white",
  position = "center",
  spacing = 2,
  className = "",
}: Typography): JSX.Element => (
  <h1
    className={`display-3 fw-bolder letter-spacing-${spacing} text-${position} ${textColorHandle(
      color
    )}  ${className}`}
  >
    {children}
  </h1>
);

export const Heading3 = ({
  children,
  color = "white",
  position = "center",
  spacing = 2,
  className = "",
}: Typography): JSX.Element => (
  <h1
    className={`display-5 fw-bolder letter-spacing-${spacing} text-${position} ${textColorHandle(
      color
    )}  ${className}`}
  >
    {children}
  </h1>
);

export const Paragraph1 = ({
  children,
  color = "white",
  position = "start",
  spacing = 1,
  className = "",
}: Typography): JSX.Element => (
  <p
    className={`fs-2 fw-light letter-spacing-${spacing} text-${position} ${textColorHandle(
      color
    )}  ${className}`}
  >
    {children}
  </p>
);

export const Paragraph2 = ({
  children,
  color = "white",
  position = "start",
  spacing = 1,
  className = "",
}: Typography): JSX.Element => (
  <p
    className={`fs-5 fw-light letter-spacing-${spacing} text-${position} ${textColorHandle(
      color
    )} ${className}`}
  >
    {children}
  </p>
);
export const Paragraph3 = ({
  children,
  color = "white",
  position = "start",
  spacing = 1,
  className = "",
}: Typography): JSX.Element => (
  <p
    className={`fs-6 fw-light letter-spacing-${spacing} text-${position} ${textColorHandle(
      color
    )} ${className}`}
  >
    {children}
  </p>
);
